<script>
  import Button, { Label } from "@smui/button";
  import Dialog, { Actions, Content, Title } from "@smui/dialog";
  import { _ } from "svelte-i18n";

  /** @type {boolean} */
  export let open;
  /** @type {import("~/libs/commonTypes").TrackingResult} */
  export let result;
</script>

<Dialog
  bind:open
  aria-labelledby="additional-info-dialog-title"
  aria-describedby="additional-info-dialog-content"
  style="margin-top: 50px; max-height: 90%"
>
  <Title id="additional-info-dialog-title"
    >{$_("pages.Tracking.AdditionalInfoDialog.title")}</Title
  >
  <Content id="additional-info-dialog-content">
    {#if result.podPhotoUrl}
      <img class="photo" src={result.podPhotoUrl} alt="locationImage" />
    {/if}
    {#if result.deliveryBoxNumber || result.deliveryBoxPin}
      <table>
        <tr>
          <th>{$_("pages.Tracking.AdditionalInfoDialog.boxNumber")}</th>
          {#if result.deliveryBoxNumber}
            <td>{result.deliveryBoxNumber}</td>
          {:else}
            <td>-</td>
          {/if}
        </tr>
        <tr>
          <th>{$_("pages.Tracking.AdditionalInfoDialog.boxPin")}</th>
          {#if result.deliveryBoxPin}
            <td>{result.deliveryBoxPin}</td>
          {:else}
            <td>-</td>
          {/if}
        </tr>
      </table>
    {/if}
  </Content>
  <Actions>
    <Button on:click={() => (open = false)}>
      <Label>{$_("pages.Tracking.AdditionalInfoDialog.closeButtonLabel")}</Label
      >
    </Button>
  </Actions>
</Dialog>

<style lang="scss">
  table {
    width: 90%;
    margin: 10px auto;
  }
  th,
  td {
    width: 45%;
    padding: 6px 6px 6px 12px;
    text-align: left;
    border: 1px solid #999;
  }
  th {
    background-color: #f5f5f5;
  }

  @media screen and (min-width: 810px) {
    .photo {
      max-width: 400px;
      max-height: 500px;
    }
  }
  @media screen and (max-width: 809px) {
    .photo {
      max-width: 280px;
      max-height: 350px;
    }
  }
</style>
